
<template>
  <div id="box">
    <h1>老年抑郁量表问卷</h1>
    <p>请根据您最近一周以来最切合的感受进行选择。</p>
    <div>
      <h4>1.你对生活基本上满意吗？</h4>
      <el-radio-group v-model="radio">
        <el-radio label="0">A：是</el-radio><br />
        <el-radio label="1">B：否 </el-radio><br /> </el-radio-group
      ><br />
      <h4>2.你是否已经放弃了许多活动和兴趣？</h4>
      <el-radio-group v-model="radio1">
        <el-radio label="1">A：是</el-radio><br />
        <el-radio label="0">B：否 </el-radio><br /> </el-radio-group
      ><br />
      <br />
      <h4>3.你是否觉得生活空虚？</h4>
      <el-radio-group v-model="radio2">
        <el-radio label="1">A：是</el-radio><br />
        <el-radio label="0">B：否 </el-radio><br /></el-radio-group
      ><br />
      <br />
      <h4>4.你是否常感到厌倦？</h4>
      <el-radio-group v-model="radio3">
        <el-radio label="1">A：是</el-radio><br />
        <el-radio label="0">B：否 </el-radio><br /></el-radio-group
      ><br />
      <br />
    </div>
    <br />
    <h4>5.你觉得未来有希望吗？</h4>
    <el-radio-group v-model="radio4">
      <el-radio label="0">A：是</el-radio><br />
      <el-radio label="1">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>6.你是否因为脑子里有一些想法摆脱不掉而烦恼？</h4>
    <el-radio-group v-model="radio5">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>7.你是否大部分时间精力充沛？</h4>
    <el-radio-group v-model="radio6">
      <el-radio label="0">A：是</el-radio><br />
      <el-radio label="1">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>8.你是否害怕会有不幸的事落到你头上？</h4>
    <el-radio-group v-model="radio7">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>9.你是否大部分时间感到幸福？</h4>
    <el-radio-group v-model="radio8">
      <el-radio label="0">A：是</el-radio><br />
      <el-radio label="1">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>10.你是否常感到孤立无援？</h4>
    <el-radio-group v-model="radio9">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>11.你是否经常坐立不安，心烦意乱？</h4>
    <el-radio-group v-model="radio10">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>12.你是否希望呆在家里而不愿意去做些新鲜事？</h4>
    <el-radio-group v-model="radio11">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>13.你是否常常担心将来？</h4>
    <el-radio-group v-model="radio12">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>14.你是否觉得记忆力比以前差？</h4>
    <el-radio-group v-model="radio13">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>15.你觉得现在生活很惬意？</h4>
    <el-radio-group v-model="radio14">
      <el-radio label="0">A：是</el-radio><br />
      <el-radio label="1">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>16.你是否常感到心情沉重、郁闷？</h4>
    <el-radio-group v-model="radio15">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>17.你是否觉得象现在这样生活毫无意义？</h4>
    <el-radio-group v-model="radio16">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>18.你是否常为过去的事忧愁？</h4>
    <el-radio-group v-model="radio17">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>19.你觉得生活很令人兴奋吗？</h4>
    <el-radio-group v-model="radio18">
      <el-radio label="0">A：是</el-radio><br />
      <el-radio label="1">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>20.你开始一件新的工作困难吗？</h4>
    <el-radio-group v-model="radio19">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>21.你觉得生活充满活力吗？</h4>
    <el-radio-group v-model="radio20">
      <el-radio label="0">A：是</el-radio><br />
      <el-radio label="1">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>22.你是否觉得你的处境毫无希望？</h4>
    <el-radio-group v-model="radio21">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>23.你是否觉得大多数人比你强的多？</h4>
    <el-radio-group v-model="radio22">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>24.你是否常为些小事伤心？</h4>
    <el-radio-group v-model="radio23">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>25.你是否常觉得想哭？</h4>
    <el-radio-group v-model="radio24">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>26.你集中精力困难吗？</h4>
    <el-radio-group v-model="radio25">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>27.你早晨起的很快活吗？</h4>
    <el-radio-group v-model="radio26">
      <el-radio label="0">A：是</el-radio><br />
      <el-radio label="1">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>28.你希望避开聚会吗？</h4>
    <el-radio-group v-model="radio27">
      <el-radio label="1">A：是</el-radio><br />
      <el-radio label="0">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>29.你做决定很容易吗？</h4>
    <el-radio-group v-model="radio28">
      <el-radio label="0">A：是</el-radio><br />
      <el-radio label="1">B：否 </el-radio><br /></el-radio-group
    ><br />
    <h4>30.你的头脑象往常一样清晰吗？</h4>
    <el-radio-group v-model="radio29">
      <el-radio label="0">A：是</el-radio><br />
      <el-radio label="1">B：否 </el-radio><br /></el-radio-group
    ><br />
    <el-button type="success" @click="ExamSolutionADD" :disabled="isdisabled"
      >提交</el-button
    >
  </div>
</template>

<script>
import { Message } from "element-ui";
import axios from "../../http/axios";
export default {
  mounted() {
    this.get();
  },
  updated() {
    // console.log("总分：", this.total);
    // console.log("说明", this.explain);
  },
  methods: {
    async ExamSolutionADD() {
      const data = await axios({
        url: `/ExamSolution/Add?ETID=${this.ETID}&ETName=老年抑郁量表&CID=${this.cid}&CName=${this.name}&Data=暂无&Score=${this.total}&Solution=${this.explain}&Review=暂无建议&Section=/&Doctor=/`,
        method: "post",
        data,
      });
      if (data.data.data == true) {
        Message.success("提交成功");
        this.$router.push("/Assessment");
      }
    },
    //  获取用户的token 中保存的username 并渲染到头部区域 用户：的位置
    get() {
      const patientN = localStorage.getItem("patientN");
      const patientI = localStorage.getItem("patientI");
      this.name = JSON.parse(patientN);
      this.cid = JSON.parse(patientI);
    },
  },
  computed: {
    isdisabled() {
      if (
        this.radio == "" ||
        this.radio1 == "" ||
        this.radio2 == "" ||
        this.radio3 == "" ||
        this.radio4 == "" ||
        this.radio5 == "" ||
        this.radio6 == "" ||
        this.radio7 == "" ||
        this.radio8 == "" ||
        this.radio9 == "" ||
        this.radio10 == "" ||
        this.radio11 == "" ||
        this.radio12 == "" ||
        this.radio13 == "" ||
        this.radio14 == "" ||
        this.radio15 == "" ||
        this.radio16 == "" ||
        this.radio17 == "" ||
        this.radio18 == "" ||
        this.radio19 == "" ||
        this.radio20 == "" ||
        this.radio21 == "" ||
        this.radio22 == "" ||
        this.radio23 == "" ||
        this.radio24 == "" ||
        this.radio25 == "" ||
        this.radio26 == "" ||
        this.radio27 == "" ||
        this.radio28 == "" ||
        this.radio29 == ""
      ) {
        return true;
      }
      if (
        this.radio != "" ||
        this.radio1 != "" ||
        this.radio2 != "" ||
        this.radio3 != "" ||
        this.radio4 != "" ||
        this.radio5 != "" ||
        this.radio6 != "" ||
        this.radio7 != "" ||
        this.radio8 != "" ||
        this.radio9 != "" ||
        this.radio10 != "" ||
        this.radio11 != "" ||
        this.radio12 != "" ||
        this.radio13 != "" ||
        this.radio14 != "" ||
        this.radio15 != "" ||
        this.radio16 != "" ||
        this.radio17 != "" ||
        this.radio18 != "" ||
        this.radio19 != "" ||
        this.radio20 != "" ||
        this.radio21 != "" ||
        this.radio22 != "" ||
        this.radio23 != "" ||
        this.radio24 != "" ||
        this.radio25 != "" ||
        this.radio26 != "" ||
        this.radio27 != "" ||
        this.radio28 != "" ||
        this.radio29 != ""
      ) {
        return false;
      }
    },
    explain() {
      if (this.total >= 0 && this.total <= 10) {
        return "正常";
      }
      if (this.total >= 11 && this.total <= 20) {
        return "轻度抑郁";
      }
      if (this.total >= 21 && this.total <= 30) {
        return "中重度抑郁";
      }
    },
    total() {
      return (
        this.radio * 1 +
        this.radio1 * 1 +
        this.radio2 * 1 +
        this.radio3 * 1 +
        this.radio4 * 1 +
        this.radio5 * 1 +
        this.radio6 * 1 +
        this.radio7 * 1 +
        this.radio8 * 1 +
        this.radio9 * 1 +
        this.radio10 * 1 +
        this.radio11 * 1 +
        this.radio12 * 1 +
        this.radio13 * 1 +
        this.radio14 * 1 +
        this.radio15 * 1 +
        this.radio16 * 1 +
        this.radio17 * 1 +
        this.radio18 * 1 +
        this.radio19 * 1 +
        this.radio20 * 1 +
        this.radio21 * 1 +
        this.radio22 * 1 +
        this.radio23 * 1 +
        this.radio24 * 1 +
        this.radio25 * 1 +
        this.radio26 * 1 +
        this.radio27 * 1 +
        this.radio28 * 1 +
        this.radio29 * 1
      );
    },
  },
  data() {
    return {
      ETID: "E5",
      isShow: false,
      name: "",
      cid: "",
      radio: "",
      radio1: "",
      radio2: "",
      radio3: "",
      radio4: "",
      radio5: "",
      radio6: "",
      radio7: "",
      radio8: "",
      radio9: "",
      radio10: "",
      radio11: "",
      radio12: "",
      radio13: "",
      radio14: "",
      radio15: "",
      radio16: "",
      radio17: "",
      radio18: "",
      radio19: "",
      radio20: "",
      radio21: "",
      radio22: "",
      radio23: "",
      radio24: "",
      radio25: "",
      radio26: "",
      radio27: "",
      radio28: "",
      radio29: "",
    };
  },
};
</script>

<style lang='scss' scoped>
// @import "./style.scss";
.el-radio {
  margin: 10px;
}
body {
  background: rgb(220, 245, 179);
}
#box {
  width: 100%;
  margin: auto;
  text-align: left;
  margin-left: 30px;
  // background: rgb(206, 245, 175);
}
.label_color {
  color: rgb(202, 44, 207);
}
h1 {
  text-align: center;
}

h4 {
  color: rgb(140, 102, 245);
}
</style>

